import React from "react";
import { Button } from "antd";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import useSubsTriggerPayment from "../../hooks/Subscription/useSubsTriggerPayment";

const SubsProceedButton = () => {
  const { subsTriggerPayment, payBtnLoading } = useSubsTriggerPayment();
  const { isPaymentBtnDisabled } = useSubscriptionContext();

  const handlePay = async () => {
    await subsTriggerPayment();
  };

  return (
    <div className="mt-4 sm:mt-6">
      <Button
        size="large"
        className="font-sans font-semibold"
        block
        onClick={handlePay}
        loading={payBtnLoading}
        disabled={isPaymentBtnDisabled}
      >
        Subscribe
      </Button>
    </div>
  );
};

export default SubsProceedButton;
