import React, { useState } from "react";
import TestCard from "./TestCard";
import {
  // authNetTestCards,
  stripeTestCards,
  tokyoTestCards,
} from "../../constants/testCards";
import { PG } from "../../constants/enums/pg";

const getTestCards = (pg) => {
  switch (pg) {
    case PG.STRIPE:
      return stripeTestCards;
    case PG.TOKYO:
      return tokyoTestCards;
    // case PG.AUTHNET:
    //   return authNetTestCards;
    default:
      return null;
  }
};

const TestCardsContainer = ({ pg }) => {
  const [isDesktopCollapsed, setDesktopCollapsed] = useState(true);
  const testCards = getTestCards(pg);

  return (
    <div className="fixed bottom-4 right-4 z-[10000]">
      {isDesktopCollapsed ? (
        <button
          className="px-4 py-2 text-white bg-blue-500 rounded-full shadow-lg"
          onClick={() => setDesktopCollapsed(false)}
        >
          Show Test Cards
        </button>
      ) : (
        <div className="p-4 bg-white rounded-lg shadow-lg ">
          <div className="flex items-center justify-between gap-10">
            <h3 className="text-lg font-semibold">Test Cards</h3>
            <button
              className="text-sm text-gray-500"
              onClick={() => setDesktopCollapsed(true)}
            >
              Collapse
            </button>
          </div>
          <div className="pt-4 text-green-600">Success Cards:</div>
          <div className="flex flex-col gap-2 pt-2">
            {testCards.success.map((card, index) => (
              <div
                key={index}
                className="flex items-start justify-between gap-4 p-4 text-black border rounded-lg shadow-sm drop-shadow-lg"
              >
                <TestCard card={card} pg={pg} />
              </div>
            ))}
          </div>
          <div className="pt-4 text-red-400">Failure Cards:</div>
          <div className="flex flex-col gap-2 pt-2">
            {testCards.failure.map((card, index) => (
              <div
                key={index}
                className="flex items-start justify-between gap-4 p-4 text-black border rounded-lg shadow-sm drop-shadow-lg"
              >
                <TestCard card={card} pg={pg} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TestCardsContainer;
