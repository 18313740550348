/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Spin } from "antd";

import { ImSpinner2 } from "react-icons/im";

import { useRetrieveTokyoPayment } from "../hooks/OneTimePayment/useRetrieveTokyoPayment";

const IframeHandler = () => {
  const { retrieveTokyoPayment } = useRetrieveTokyoPayment();

  useEffect(() => {
    const paymentStatushandler = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tnxReferenceId = urlParams.get("payment_reference_id");
      const mode = urlParams.get("mode");

      if (tnxReferenceId) {
        const { status, errorMessage } = await retrieveTokyoPayment({
          tnxReferenceId: tnxReferenceId,
          mode: mode,
        });

        window.parent.postMessage({ status, errorMessage }, window.origin);
      }
    };

    paymentStatushandler();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <Spin
        spinning={true}
        indicator={<ImSpinner2 className="animate-spin" />}
        size="large"
      ></Spin>
    </div>
  );
};

export default IframeHandler;
