export const getCurrencySymbol = (currency) => {
  let symbol = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).format(0);

  symbol = symbol.replace(/\d/g, "");

  return currency === symbol.slice(0, -1)
    ? currency
    : `${currency} (${symbol})`;
};

export const getCurrencyCountryName = (currency) => {
  const regionName = new Intl.DisplayNames(["en"], { type: "region" });
  const regionCode = currency.slice(0, 2).toUpperCase();
  const countryName = regionName.of(regionCode);

  return countryName || "";
};

export const getCurrencyName = (currency) => {
  const currencyNameFormatter = new Intl.DisplayNames(["en"], {
    type: "currency",
  });
  return currencyNameFormatter.of(currency) || "";
};
