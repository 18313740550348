/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PaypalCheckout from "./PaypalCheckout";
import AfterpayCheckout from "./AfterpayCheckout";
import VenmoCheckout from "./VenmoCheckout";
import KlarnaCheckout from "./KlarnaCheckout";
import { PaymentMethod } from "../../../constants/enums/paymentMethod";
import CardCheckout from "./CardCheckout";
import { Form } from "antd";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";

const TokyoGateway = ({ paymentMethod }) => {
  const [form] = Form.useForm();
  const { setIsPaymentBtnDisabled, setForm } = useOneTimePaymentsContext();

  useEffect(() => {
    setIsPaymentBtnDisabled(false);
    setForm(form);
  }, []);

  const renderPg = () => {
    switch (paymentMethod) {
      case PaymentMethod.CARD:
        return <CardCheckout />;
      case PaymentMethod.PAYPAL:
        return <PaypalCheckout />;
      case PaymentMethod.VENMO:
        return <VenmoCheckout />;
      case PaymentMethod.AFTERPAY:
        return <AfterpayCheckout />;
      case PaymentMethod.KLARNA:
        return <KlarnaCheckout />;
      default:
        return <></>;
    }
  };

  return (
    <Form
      form={form}
      name={`tokyoCheckout`}
      className="font-sans"
      layout="vertical"
      autoComplete="on"
      requiredMark={"optional"}
    >
      {renderPg()}
    </Form>
  );
};

export default TokyoGateway;
