import { CiCreditCard1 } from "react-icons/ci";
import amazonPay from "../assets/paymentMethods/amazon-pay.png";
import applePay from "../assets/paymentMethods/apple-pay.png";
import CashAppPay from "../assets/paymentMethods/Cash-App.png";
import Affirm from "../assets/paymentMethods/affirm.webp";
import KlarnaUSA from "../assets/paymentMethods/KlarnaUSA.png";
import googlePay from "../assets/paymentMethods/google-pay.png";
import afterpay from "../assets/paymentMethods/afterpay.png";
import paypal from "../assets/paymentMethods/paypal.png";
import venmo from "../assets/paymentMethods/venmo.png";
import { PaymentMethod } from "./enums/paymentMethod";

export const paymentMethodContent = [
  {
    id: PaymentMethod.CARD,
    title: "Card",
    description: "Pay securely using your credit or debit card.",
    icon: <CiCreditCard1 size={28} />,
    value: PaymentMethod.CARD,
    showText: true,
  },
  {
    id: PaymentMethod.AMAZON_PAY,
    title: "Amazon Pay",
    description: "Use your Amazon account for a fast and secure checkout.",
    icon: <img src={amazonPay} alt="amazonPay" className="w-8 mt-2" />,
    value: PaymentMethod.AMAZON_PAY,
    showText: true,
  },
  {
    id: PaymentMethod.APPLE_PAY,
    title: "Apple Pay",
    description:
      "Make payments effortlessly with Apple Pay on supported devices.",
    icon: <img src={applePay} alt="applePay" className="mt-1 w-9" />,
    value: PaymentMethod.APPLE_PAY,
    showText: true,
  },
  {
    id: PaymentMethod.GOOGLE_PAY,
    title: "Google Pay",
    description:
      "Make payments effortlessly with Google Pay on supported devices.",
    icon: <img src={googlePay} alt="googlePay" className="w-12" />,
    value: PaymentMethod.GOOGLE_PAY,
    showText: true,
  },
  {
    id: PaymentMethod.CASH_APP,
    title: "Cash App",
    description: "Pay using Cash App for a quick and easy transaction.",
    icon: <img src={CashAppPay} alt="cashAppPay" className="w-7" />,
    value: PaymentMethod.CASH_APP,
    showText: true,
  },
  {
    id: PaymentMethod.AFFIRM,
    title: "Affirm",
    description: "Pay using Affirm for a quick and easy transaction.",
    icon: <img src={Affirm} alt="affirm" className="w-12" />,
    value: PaymentMethod.AFFIRM,
    showText: false,
  },
  {
    id: PaymentMethod.KLARNA,
    title: "Klarna",
    description: "Pay using klarna for a quick and easy transaction.",
    icon: <img src={KlarnaUSA} alt="affirm" className="w-16" />,
    value: PaymentMethod.KLARNA,
    showText: false,
  },
  {
    id: PaymentMethod.AFTERPAY,
    title: "Afterpay",
    description: "Pay using Afterpay for a quick and easy transaction.",
    icon: <img src={afterpay} alt="affirm" className="w-24" />,
    value: PaymentMethod.AFTERPAY,
    showText: false,
  },
  {
    id: PaymentMethod.PAYPAL,
    title: "Paypal",
    description: "Pay using Paypal for a quick and easy transaction.",
    icon: <img src={paypal} alt="Paypal" className="w-8" />,
    value: PaymentMethod.PAYPAL,
    showText: true,
  },
  {
    id: PaymentMethod.VENMO,
    title: "Venmo",
    description: "Pay using Venmo for a quick and easy transaction.",
    icon: <img src={venmo} alt="venmo" className="w-8" />,
    value: PaymentMethod.VENMO,
    showText: true,
  },
];
