/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import axios from "../../axios";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

const useSubsCalculateAmount = () => {
  const { subscriptionId, setStatus, setCurrency, amount, setAmount, setUpfrontAmount } =
    useSubscriptionContext();
  const [amountLoading, setAmountLoading] = useState(false);
  const getSubsCalculatedAmount = async ({ value }) => {
    setAmountLoading(true);
    try {
      const { data } = await axios.post(
        `/subscription/calculate-amount/${subscriptionId}`,
        { currency: value }
      );

      setAmount(data.amount);
      setCurrency(data.currency);
      setUpfrontAmount(data.upfrontAmount);
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setAmountLoading(false);
    }
  };

  return { amount, amountLoading, getSubsCalculatedAmount };
};

export default useSubsCalculateAmount;
