import { useState } from "react";

import { toast } from "react-hot-toast";

import axios from "../../axios";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";
import SuccessToast from "../../components/UI/Toasts/SuccessToast";

const useSubsPostIngestBillingAddress = () => {
  const { subscriptionId, setStatus } = useSubscriptionContext();

  const [loading, setLoading] = useState(false);

  const subsPostIngestBillingAddress = async (values, showToast) => {
    setLoading(true);
    try {
      await axios.post(`/subscription/ingest-billing-address`, {
        subscriptionId: subscriptionId,
        billingDetails: {
          name: values?.name,
          email: values?.email,
          customerAddress: {
            postalCode: values?.pincode,
            country: values?.country.split("-")[0],
          },
        },
      });

      if (showToast) {
        toast.custom(
          (t) => (
            <SuccessToast
              t={t}
              message="Billing details updated successfully!"
            />
          ),
          {
            id: "userDetails",
          }
        );
      }
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { loading, subsPostIngestBillingAddress };
};

export default useSubsPostIngestBillingAddress;
