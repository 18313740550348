import React from "react";
import GridBackground from "../components/UI/GridBackground";
import Container from "../components/OneTimePayment/Container";
import { OneTimePaymentsProvider } from "../contexts/OneTimePaymentsContext";
import { posthog } from "posthog-js";

const OneTimePaymentsV3 = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const xIntentId = urlParams.get("xpay_intent_id");

  posthog.people.set({
    xPayId: xIntentId,
  });

  return (
    <div>
      <GridBackground>
        <OneTimePaymentsProvider>
          <Container />
        </OneTimePaymentsProvider>
      </GridBackground>
    </div>
  );
};

export default OneTimePaymentsV3;
