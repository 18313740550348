/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";

export const useRetrieveTokyoPayment = () => {
  const [status, setStatus] = useState();

  const retrieveTokyoPayment = async ({ tnxReferenceId, mode }) => {
    let res;
    try {
      res = await axios.post(`/payments/citcon/retrieve`, {
        tnxReferenceId: tnxReferenceId,
        mode: mode,
      });
    } catch (error) {
      ErrorHandler(error, setStatus);
    }

    return { status: res?.data?.status, errorMessage: res?.data?.errorMessage };
  };

  return { retrieveTokyoPayment };
};
