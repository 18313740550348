/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const FeatureFlag = () => {
  const navigateTo = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const xIntentId = urlParams.get("xpay_intent_id");
  const subscriptionId = urlParams.get("subscription_id");

  const chooseVersion = async () => {
    if (subscriptionId) {
      navigateTo(`/v3/subscription/?${urlParams.toString()}`, {
        replace: true,
      });
    } else if (xIntentId) {
      navigateTo(`/v3/?${urlParams.toString()}`, { replace: true });
    } else {
      navigateTo(`/v3/?${urlParams.toString()}`, { replace: true }); // Error Page
    }
  };

  useEffect(() => {
    chooseVersion();
  }, []);

  return (
    <div className="flex items-center justify-center h-[100vh]">
      <div className="circular-loader" />
    </div>
  );
};

export default FeatureFlag;
