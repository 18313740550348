export const STATUS = Object.freeze({
  SUCCESS: "SUCCESS",
  CREATED: "CREATED",
  TERMINAL_STATE: "TERMINAL_STATE",
  ERROR: "ERROR",
  FAILED: "FAILED",
  LOADING: "LOADING",
  INCOMPLETE: "INCOMPLETE",
  REFUNDED: "REFUNDED",
});
