/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import { Form } from "antd";
import InputField from "../../UI/FormFields/InputField";
import CheckoutFooter from "./../../UI/CheckoutFooter";
import { fieldNames } from "../../../constants/fieldNames";

const AfterpayCheckout = () => {
  const { billingDetails } = useOneTimePaymentsContext();
  const form = Form.useFormInstance();

  useEffect(() => {
    const initialValues = {
      email: billingDetails?.email,
    };

    form.setFieldsValue(initialValues);
  }, [billingDetails]);

  return (
    <div>
      <InputField
        name={fieldNames.email}
        label={"Email"}
        required={true}
        placeholder={"Email Address"}
      />
      <CheckoutFooter
        text={
          "After submission, you will be redirected to securely complete next steps."
        }
      />
    </div>
  );
};

export default AfterpayCheckout;
