import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

const usePaymentConfirmation = () => {
  const { setStatus, intentDetails, secret } = useOneTimePaymentsContext();

  const triggerRedirect = async () => {
    try {
      if (!intentDetails?.callbackUrl) {
        ErrorHandler(new Error("callbackUrl not found"), setStatus);
        return;
      }
      const callback = new URL(intentDetails.callbackUrl);
      callback.searchParams.set("xpay_intent_id", intentDetails.xIntentId);
      callback.searchParams.set("secret", secret);
      setTimeout(() => window.location.replace(callback.href), 5000);
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  return {
    triggerRedirect,
  };
};

export default usePaymentConfirmation;
