export const SUBS_STATUS = Object.freeze({
    ACTIVE: "ACTIVE",
    COMPLETED: "COMPLETED",
    INACTIVE: "INACTIVE",
    INCOMPLETE: "INCOMPLETE",
    CREATED: "CREATED",
    CANCELLED: "CANCELLED",
    LOADING: "LOADING",
    ERROR: "ERROR",
  });
  