import React from "react";
import { ReactComponent as InfoIcon } from "../../assets/Icons/toast/info.svg";

const CheckoutTooltip = ({ text }) => {
  return (
    <div className="text-sm font-medium flex gap-2 p-2 bg-[#EEF8FF] border border-[#DFF2FF] rounded-lg">
      <div className="w-5 h-5">
        <InfoIcon />
      </div>
      {text}
    </div>
  );
};

export default CheckoutTooltip;
