/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import axios from "../../axios";
import { paymentMethodContent } from "../../constants/paymentMethod";
import { ErrorHandler } from "../../utils/ErrorHandler";
import useTriggerIntent from "./useTriggerIntent";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../../config";
import { PaymentMethod } from "../../constants/enums/paymentMethod";
import { STATUS } from "../../constants/enums/status";

const usePaymentMethod = () => {
  const {
    xIntentId,
    setStatus,
    loadingMethods,
    setLoadingMethods,
    setPaymentMethod,
    paymentRequest,
    setPaymentRequest,
    amount,
    currency,
    setErrorMessage,
  } = useOneTimePaymentsContext();

  const { triggerIntent } = useTriggerIntent();

  const [paymentMethodList, setPaymentMethodList] = useState();

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        currency: currency.toLowerCase(),
        total: {
          label: "Total",
          amount: amount,
        },
      });
    }
  }, [amount, currency, paymentMethodList]);

  const removePaymentMethod = (methodId, paymentMethodOptions) => {
    const index = paymentMethodOptions.findIndex(
      (method) => method.id === methodId
    );
    if (index !== -1) {
      paymentMethodOptions.splice(index, 1);
    }
  };

  const handlePaymentRequest = async (prefetchData, paymentMethodOptions) => {
    const promise = await loadStripe(config.stripe_pg_public_key);

    const paymentRequest = promise.paymentRequest({
      country: "US",
      currency:
        currency.toLowerCase() || prefetchData?.primary?.currency.toLowerCase(),
      total: {
        label: "Total",
        amount: amount || prefetchData?.primary?.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    setPaymentRequest(paymentRequest);

    const result = await paymentRequest.canMakePayment();
    if (result) {
      if (!result.applePay)
        removePaymentMethod(PaymentMethod.APPLE_PAY, paymentMethodOptions);
      if (!result.googlePay)
        removePaymentMethod(PaymentMethod.GOOGLE_PAY, paymentMethodOptions);
    } else {
      removePaymentMethod(PaymentMethod.APPLE_PAY, paymentMethodOptions);
      removePaymentMethod(PaymentMethod.GOOGLE_PAY, paymentMethodOptions);
    }

    if (prefetchData?.country === "IN") {
      removePaymentMethod(PaymentMethod.APPLE_PAY, paymentMethodOptions);
      removePaymentMethod(PaymentMethod.GOOGLE_PAY, paymentMethodOptions);
    }
  };

  const getPaymentMethod = async (value, prefetchData) => {
    setLoadingMethods(true);
    try {
      const { data } = await axios.post(`/payments/v2/prefetchPaymentMethods`, {
        intentId: xIntentId,
        currency: value,
      });

      const paymentMethodOptions = [];
      data.paymentMethods.forEach((item) => {
        paymentMethodOptions.push(
          paymentMethodContent.find((method) => method.id === item)
        );
      });

      await handlePaymentRequest(prefetchData, paymentMethodOptions);

      if (paymentMethodOptions.length === 1) {
        setPaymentMethod(paymentMethodOptions[0].value);
        await triggerIntent({
          value: paymentMethodOptions[0].value,
          currency: value,
        });
      }

      if (paymentMethodOptions.length === 0) {
        setErrorMessage("No payment methods available");
        setStatus(STATUS.ERROR);
      }

      setPaymentMethodList(paymentMethodOptions);
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoadingMethods(false);
    }
  };

  return {
    getPaymentMethod,
    paymentMethodList,
    loadingMethods,
    setLoadingMethods,
  };
};

export default usePaymentMethod;
