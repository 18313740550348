/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Input, Form } from "antd";
import { getValidationRules } from "../../../utils/validations";
import { cardBrand } from "../../../constants/cards";
import { fieldNames } from "../../../constants/fieldNames";
var valid = require("card-validator");

const ChargeCardInputField = ({
  label,
  name,
  required,
  placeholder,
  suffix,
}) => {
  const [suffixIcon, setSuffixIcon] = useState(suffix);
  const [maxLength, setMaxLength] = useState(320);
  const [minLength, setMinLength] = useState(0);

  const form = Form.useFormInstance();

  function formatCreditCard(number, gaps) {
    let cleaned = number.replace(/\D/g, "");
    for (let i = 0; i < gaps.length; i++) {
      if (cleaned.length > gaps[i]) {
        cleaned =
          cleaned.slice(0, gaps[i] + i) + " " + cleaned.slice(gaps[i] + i);
      }
    }

    return cleaned;
  }

  const handleCardNumberChange = (value) => {
    const numberValidation = valid.number(value);
    if (numberValidation.card) {
      const gaps = numberValidation.card.gaps;
      const size =
        numberValidation.card.lengths[numberValidation.card.lengths.length - 1];
      value = formatCreditCard(value, gaps);
      setMaxLength(size + gaps.length);
      setSuffixIcon(cardBrand(numberValidation.card.type));
    } else {
      setSuffixIcon(cardBrand("default"));
    }
    return value;
  };

  const handleCardValidityChange = (value) => {
    if (value.length >= 2 && parseInt(value.slice(0, 2)) > 12) {
      value = "0" + value[0];
    }
    value = value.replace(/[^\d]/g, "");
    const matches = value.match(/.{1,2}/g);
    return matches ? matches.join(" / ") : "";
  };

  const handleCardSecurityCodeChange = () => {
    const cardValidation = valid.number(
      form.getFieldValue(fieldNames.cardNumber)
    );
    if (cardValidation?.card) {
      setMaxLength(cardValidation.card.code.size || 3);
      setMinLength(cardValidation.card.code.size || 3);
    }
  };

  const handleChange = (e) => {
    let { value } = e.target;
    switch (name) {
      case fieldNames.cardNumber:
        value = handleCardNumberChange(value);
        break;
      case fieldNames.cardValidity:
        value = handleCardValidityChange(value);
        setMaxLength(7);
        break;
      case fieldNames.cardSecurityCode:
        handleCardSecurityCodeChange();
        break;
      default:
        break;
    }
    form.setFieldsValue({ [name]: value });
  };

  return (
    <div>
      <Form.Item
        hasFeedback
        label={<div className="text-[#374151]">{label} </div>}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
        className="font-sans"
      >
        <Input
          size="large"
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          suffix={suffixIcon}
          maxLength={maxLength}
          minLength={minLength}
        />
      </Form.Item>
    </div>
  );
};

export default ChargeCardInputField;
