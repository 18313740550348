/* eslint-disable react-hooks/exhaustive-deps */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
import SubsStripeCheckoutForm from "./SubsStripeCheckoutForm";

const SubsStripeGateway = () => {
  const { clientSecret, pgPublicKey, setStripePromise } = useSubscriptionContext();

  const stripePromise = useMemo(() => {
    const promise = loadStripe(pgPublicKey);
    promise.then((stripe) => {
      setStripePromise(stripe);
    });
    return promise;
  }, [pgPublicKey]);

  const appearance = {
    theme: "stripe",
    variables: {
      fontFamily: 'Outfit, sans-serif',
      borderRadius: "2px",
      tabIconSelectedColor: "#fff",
      gridRowSpacing: "24px",
      fontSizeSm: "14px",
    },
    rules: {
      ".Input, .Block, .CheckboxInput, .CodeInput": {
        boxShadow: "0px 0px 0px 0px",
        borderRadius: "8px",
      },
      ".Tab--selected, .Tab--selected:hover": {
        backgroundColor: "#fff",
      },
      ".Label": {
        paddingBottom: "12px",
        marginBottom: "0px",
      },
      ".Error": {
        color: "#ff4d4f",
      },
      ".Input--invalid": {
        borderColor: "#ff4d4f",
      },
    },
  };

  const options = {
    clientSecret: clientSecret,
    appearance: appearance,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap",
      },
    ],
    loader: "auto",
  };

  return (
    <div>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <SubsStripeCheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default SubsStripeGateway;
