/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Input, Form } from "antd";
import { getValidationRules } from "../../../utils/validations";

const InputField = ({ label, name, required, placeholder }) => {
  return (
    <div>
      <Form.Item
        hasFeedback
        label={<div className="text-[#374151]">{label} </div>}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
        className="font-sans"
        required={required}
      >
        <Input
          size="large"
          name={name}
          placeholder={placeholder}
          className="font-sans"
        />
      </Form.Item>
    </div>
  );
};

export default InputField;
