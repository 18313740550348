import React from "react";
import GridBackground from "../components/UI/GridBackground";

const PageNotFound = () => {
  return (
    <GridBackground>
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center justify-center gap-4 p-12 my-6 rounded-lg shadow-lg bg-opacity-70 backdrop-blur-sm">
          <h2 className="text-4xl font-bold text-center">🔍</h2>
          <h3 className="text-4xl font-bold text-center">Page not found</h3>
          <p className="text-center text-gray-500">
            Please check the link you are trying to visit
          </p>
        </div>
      </div>
    </GridBackground>
  );
};

export default PageNotFound;
