export const PaymentMethod = Object.freeze({
  CARD: "CARD",
  AMAZON_PAY: "AMAZON_PAY",
  APPLE_PAY: "APPLE_PAY",
  GOOGLE_PAY: "GOOGLE_PAY",
  PAYPAL: "PAYPAL",
  VENMO: "VENMO",
  CASH_APP: "CASH_APP",
  AFFIRM: "AFFIRM",
  KLARNA: "KLARNA",
  AFTERPAY: "AFTERPAY",
});
