/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import venmo from "../../../assets/paymentMethods/venmo.png";
import CheckoutFooter from "./../../UI/CheckoutFooter";

const VenmoCheckout = () => {
  return (
    <div className="border border-[#e6e6e6] p-3 rounded-lg">
      <img className="mb-3 h-[2em] w-auto" src={venmo} alt="venmo" />
      <p className="text-[#30313D]">Venmo selected.</p>
      <div className="h-[1px] bg-[#e6e6e6] w-full my-3"></div>
      <CheckoutFooter
        text={
          "Another step will appear to securely submit your payment information."
        }
      />
    </div>
  );
};

export default VenmoCheckout;
