/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import StripeCheckoutForm from "./StripeCheckoutForm";
import GooglePayCheckoutPage from "./GooglePayCheckoutPage";
import ApplePayCheckoutPage from "./ApplePayCheckoutPage";
import { PaymentMethod } from "../../../constants/enums/paymentMethod";

const StripeGateway = () => {
  const {
    intentDetails,
    setStripePromise,
    paymentMethod,
    setIsPaymentBtnDisabled,
  } = useOneTimePaymentsContext();

  useEffect(() => {
    if (
      paymentMethod === PaymentMethod.GOOGLE_PAY ||
      paymentMethod === PaymentMethod.APPLE_PAY
    ) {
      setIsPaymentBtnDisabled(false);
    }
  }, []);

  const stripePromise = useMemo(() => {
    const promise = loadStripe(intentDetails.pgPublicKey);
    promise.then((stripe) => {
      setStripePromise(stripe);
    });
    return promise;
  }, [intentDetails.pgPublicKey]);

  const appearance = {
    theme: "stripe",
    variables: {
      fontFamily: "Outfit, sans-serif",
      borderRadius: "2px",
      tabIconSelectedColor: "#fff",
      gridRowSpacing: "24px",
      fontSizeSm: "14px",
    },
    rules: {
      ".Input, .Block, .CheckboxInput, .CodeInput": {
        boxShadow: "0px 0px 0px 0px",
        borderRadius: "8px",
      },
      ".Tab--selected, .Tab--selected:hover": {
        backgroundColor: "#fff",
      },
      ".Label": {
        paddingBottom: "12px",
        marginBottom: "0px",
      },
      ".Error": {
        color: "#ff4d4f",
      },
      ".Input--invalid": {
        borderColor: "#ff4d4f",
      },
    },
  };

  const options = {
    clientSecret: intentDetails.clientSecret,
    appearance: appearance,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap",
      },
    ],
    loader: "auto",
  };

  return (
    <>
      {paymentMethod === PaymentMethod.GOOGLE_PAY ? (
        <GooglePayCheckoutPage />
      ) : paymentMethod === PaymentMethod.APPLE_PAY ? (
        <ApplePayCheckoutPage />
      ) : (
        <div>
          {intentDetails?.clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <StripeCheckoutForm />
            </Elements>
          )}
        </div>
      )}
    </>
  );
};

export default StripeGateway;
