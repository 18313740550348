import React from "react";
import { ReactComponent as GPayLogo } from "../../../assets/paymentMethods/GooglePay.svg";
import CheckoutFooter from "./../../UI/CheckoutFooter";

const GooglePayCheckoutPage = () => {
  return (
    <div className="border border-[#e6e6e6] p-3 rounded-lg">
      <GPayLogo className="mb-3 h-[2em] w-auto" />
      <p className="text-[#30313D]">Google Pay selected.</p>
      <div className="h-[1px] bg-[#e6e6e6] w-full my-3"></div>
      <CheckoutFooter
        text={
          "Another step will appear to securely submit your payment information."
        }
      />
    </div>
  );
};

export default GooglePayCheckoutPage;
