export const stripeTestCards = {
  success: [
    {
      card: "Visa",
      cardNumber: "4242 4242 4242 4242",
      expiryDate: `${String(new Date().getMonth() + 1).padStart(
        2,
        "0"
      )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
      csc: "444",
    },
    {
      card: "Mastercard",
      cardNumber: "5555 5555 5555 4444",
      expiryDate: `${String(new Date().getMonth() + 1).padStart(
        2,
        "0"
      )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
      csc: "555",
    },
  ],
  failure: [
    {
      card: "Visa",
      cardNumber: "4000 0000 0000 0002",
      expiryDate: `${String(new Date().getMonth() + 1).padStart(
        2,
        "0"
      )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
      csc: "402",
    },
  ],
};

// export const authNetTestCards = {
//   success: [
//     {
//       card: "Visa",
//       cardNumber: "4000 0566 5566 5556",
//       expiryDate: `${String(new Date().getMonth() + 1).padStart(
//         2,
//         "0"
//       )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
//       csc: "472",
//     },
//     {
//       card: "Mastercard",
//       cardNumber: "5424 0000 0000 0015",
//       expiryDate: `${String(new Date().getMonth() + 1).padStart(
//         2,
//         "0"
//       )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
//       csc: "015",
//     },
//   ],
//   failure: [
//     {
//       card: "Visa",
//       cardNumber: "4111 1111 1111 1111",
//       expiryDate: `${String(new Date().getMonth() + 1).padStart(
//         2,
//         "0"
//       )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
//       csc: "901",
//     },
//   ],
// };

export const tokyoTestCards = {
  success: [
    {
      card: "Visa",
      cardNumber: "4012 0000 3333 0026",
      expiryDate: `${String(new Date().getMonth() + 1).padStart(
        2,
        "0"
      )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
      csc: "123",
      cardHolderName: "John Doe",
    },
  ],
  failure: [
    {
      card: "Visa",
      cardNumber: "4012 8888 8888 1881",
      expiryDate: `${String(new Date().getMonth() + 1).padStart(
        2,
        "0"
      )} / ${String(new Date().getFullYear() + 1).slice(-2)}`,
      csc: "402",
      cardHolderName: "CCREJECT-REFUSED",
    },
  ],
};
