/* eslint-disable react-hooks/exhaustive-deps */
import PaymentStatusSuccess from "./PaymentStatusSuccess";
import PaymentStatusFailed from "./PaymentStatusFailed";
import PaymentStatusRefunded from "./PaymentStatusRefunded";
import PaymentStatusView from "../../../OneTimePayment/PaymentStatusView";
import { useGetIntent } from "../../../../hooks/OneTimePayment/useGetIntent";
import GridBackground from "../../../UI/GridBackground";
import { ImSpinner2 } from "react-icons/im";
import SubscriptionStatusSuccess from "./SubscriptionStatusSuccess";
import Header from "../../../UI/Header";
import { STATUS } from "../../../../constants/enums/status";
import { SUBS_STATUS } from "../../../../constants/enums/subsStatus";

const PaymentConfirmationScreen = () => {
  const urlParam = new URLSearchParams(window.location.search);
  const xIntentId = urlParam.get("xpay_intent_id");
  const subscriptionId = urlParam.get("subscription_id");

  const { status, loader } = useGetIntent({ xIntentId, subscriptionId });

  const renderContent = () => {
    if (xIntentId) {
      switch (status) {
        case STATUS.SUCCESS:
          return <PaymentStatusSuccess />;
        case STATUS.REFUNDED:
          return <PaymentStatusRefunded />;
        case STATUS.FAILED:
        case STATUS.INCOMPLETE:
        case STATUS.CREATED:
          return <PaymentStatusFailed />;
        case STATUS.LOADING:
        case STATUS.ERROR:
        default:
          return <PaymentStatusView id={xIntentId} />;
      }
    } else {
      switch (status) {
        case SUBS_STATUS.ACTIVE:
        case SUBS_STATUS.COMPLETED:
          return <SubscriptionStatusSuccess />;
        case SUBS_STATUS.INACTIVE:
        case SUBS_STATUS.INCOMPLETE:
        case SUBS_STATUS.CREATED:
        case SUBS_STATUS.CANCELLED:
          return <PaymentStatusFailed />;
        case SUBS_STATUS.LOADING:
        case SUBS_STATUS.ERROR:
        default:
          return <PaymentStatusView id={subscriptionId} />;
      }
    }
  };

  return (
    <GridBackground>
      <div
        className="bg-white h-full w-full max-w-[32rem] drop-shadow-2xl sm:rounded-2xl sm:py-9 sm:px-12 pt-8 pb-6 px-6 backdrop-blur shadow-2xl "
        id="container"
      >
        {loader ? (
          <div className="flex items-center justify-center h-[80vh] text-3xl">
            <ImSpinner2 className="animate-spin" />
          </div>
        ) : (
          <>
            <Header />
            {renderContent()}
          </>
        )}
      </div>
    </GridBackground>
  );
};

export default PaymentConfirmationScreen;
