/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { STATUS } from "../../constants/enums/status";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import usePaymentConfirmation from "../../hooks/OneTimePayment/usePaymentConfirmation";

import PaymentStatusSuccess from "../StatusScreens/PaymentStatusSuccess";
import PaymentStatusError from "../StatusScreens/PaymentStatusError";
import TerminalState from "../StatusScreens/TerminalState";

const PaymentStatusView = ({ status, id, errorMessage }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { triggerRedirect } = usePaymentConfirmation();
  const { loadingMain, xIntentId } = useOneTimePaymentsContext();

  switch (status) {
    case STATUS.SUCCESS:
      return (
        <PaymentStatusSuccess
          triggerRedirect={triggerRedirect}
          loadingMain={loadingMain}
        />
      );
    case STATUS.TERMINAL_STATE:
      return <TerminalState loadingMain={loadingMain} />;
    case STATUS.ERROR:
    default:
      return <PaymentStatusError id={xIntentId || id} errorMessage={errorMessage} />;
  }
};

export default PaymentStatusView;
