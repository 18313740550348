import React from "react";
import { ReactComponent as ExportIcon } from "../../assets/paymentMethods/ExportIcon.svg";

const CheckoutFooter = ({ text }) => {
  return (
    <p className="flex text-[#a7a8ae] items-center">
      <ExportIcon className="h-[3em] w-auto mr-3 text-[#a7a8ae] opacity-40" />
      <span className="text-sm">{text}</span>
    </p>
  );
};

export default CheckoutFooter;
