import React from "react";
import { paymentMethodContent as paymentMethodOptions } from "../../constants/paymentMethod";
import { FaAngleDown } from "react-icons/fa6";

const PaymentDetails = ({
  intentDetails,
  setIntentDetails,
  paymentMethod,
  paymentMethodList,
  setPaymentError,
  setIsPaymentBtnDisabled,
}) => {
  const method = paymentMethodOptions.find(
    (item) => item.value === paymentMethod
  )?.title;

  return (
    <div className="flex flex-wrap items-center gap-2">
      <div className="text-[16px] font-medium sm:text-[20px]">
        {!intentDetails ? "Payment Methods" : "Paying via"}
      </div>
      {intentDetails && paymentMethodList && (
        <div
          className={`px-3 py-1 text-sm bg-[#E9EDFF] text-[#3F5BE5] rounded-full flex gap-2 items-center ${
            paymentMethodList.length === 1 ? "" : "cursor-pointer"
          }`}
          onClick={() => {
            if (paymentMethodList.length === 1) {
              return;
            }
            setIntentDetails(null);
            setPaymentError(null);
            setIsPaymentBtnDisabled(true);
          }}
        >
          {method}
          {paymentMethodList.length !== 1 && <FaAngleDown size={12} />}
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
