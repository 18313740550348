import React from "react";
import { Button } from "antd";
import useTriggerPayment from "../../hooks/OneTimePayment/useTriggerPayment";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/enums/eventNames";

const ProceedButton = () => {
  const { triggerPayment, payBtnLoading } = useTriggerPayment();
  const { isPaymentBtnDisabled, xIntentId } = useOneTimePaymentsContext();

  const handlePay = async () => {
    sendEvent(xIntentId, EVENT_NAMES.PAY_BUTTON_CLICKED, {});
    await triggerPayment();
  };

  return (
    <div className="mt-4 sm:mt-6">
      <Button
        size="large"
        className="font-sans font-semibold"
        block
        onClick={handlePay}
        loading={payBtnLoading}
        disabled={isPaymentBtnDisabled}
      >
        Pay
      </Button>
    </div>
  );
};

export default ProceedButton;
