/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "antd";
import { ImSpinner2 } from "react-icons/im";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";

const TransDetails = ({ merchantName, amountLoading }) => {
  const { currency, amount, upfrontAmount, interval, intervalCount } =
    useSubscriptionContext();

  return (
    <div>
      <Divider className="my-4 sm:my-6" />
      <div className="flex items-center justify-between gap-4">
        <div>
          <span className="">Paying to</span>{" "}
          <span className="font-semibold">{merchantName}</span>
        </div>
        <div className="text-right">
          {amountLoading ? (
            <div className="flex items-center justify-center text-sm">
              <ImSpinner2 className="mr-2 animate-spin" />
              <span className="animate-pulse">Calculating...</span>
            </div>
          ) : (
            <div className="font-semibold">
              {upfrontAmount ? (
                <span>
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                    minimumFractionDigits: 2,
                  }).format(upfrontAmount / 100)}
                </span>
              ) : (
                <span>
                  {Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency,
                    minimumFractionDigits: 2,
                  }).format(amount / 100)}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {!amountLoading && (
        <div className="text-sm text-right text-gray-600">
          {upfrontAmount && (
            <>
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
                minimumFractionDigits: 2,
              }).format(amount / 100)}{" "}
            </>
          )}
          every {intervalCount} {interval}
          {intervalCount > 1 ? "s" : ""}
          {upfrontAmount && " thereafter"}
        </div>
      )}
    </div>
  );
};

export default TransDetails;
