/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
import posthog from "posthog-js";

const SubsStripeCheckoutForm = () => {
  const { setIsPaymentBtnDisabled, loading, setStripeElement, billingDetails } =
    useSubscriptionContext();
  const elements = useElements();

  useEffect(() => {
    if (!elements) return;
    setStripeElement(elements);

    const paymentElement = elements.getElement(PaymentElement);

    if (paymentElement) {
      paymentElement.on("ready", (event) => {
        posthog.capture("stripe_ready", { stripe_event: event });
      });

      paymentElement.on("change", (event) => {
        posthog.capture("stripe_change", { stripe_event: event });
      });

      paymentElement.on("focus", (event) => {
        posthog.capture("stripe_focus", { stripe_event: event });
      });

      paymentElement.on("blur", (event) => {
        posthog.capture("stripe_blur", { stripe_event: event });
      });

      paymentElement.on("escape", (event) => {
        posthog.capture("stripe_escape", { stripe_event: event });
      });

      paymentElement.on("click", (event) => {
        posthog.capture("stripe_click", { stripe_event: event });
      });

      paymentElement.on("loaderror", (event) => {
        posthog.capture("stripe_loaderror", { stripe_event: event });
      });

      paymentElement.on("loaderstart", (event) => {
        posthog.capture("stripe_loaderstart", { stripe_event: event });
      });
    }
  }, [elements]);

  return (
    <>
      {!loading && (
        <div className="pb-4">
          <PaymentElement
            options={{
              defaultValues: {
                billingDetails: {
                  name: billingDetails?.name,
                  email: billingDetails?.email,
                  address: {
                    postal_code: billingDetails?.pincode,
                  },
                },
              },
            }}
            onChange={(event) => {
              setIsPaymentBtnDisabled(!event.complete);
            }}
          />
        </div>
      )}
    </>
  );
};

export default SubsStripeCheckoutForm;
