export const clearUrlParams = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  for (const paramName of searchParams.keys()) {
    if (paramName !== "xpay_intent_id" && paramName !== "secret") {
      url.searchParams.delete(paramName);
    }
  }
  window.history.replaceState({}, "", url.toString());
};
