/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";

export const useGetIntent = ({ xIntentId, subscriptionId }) => {
  const [status, setStatus] = useState();
  const [loader, setLoader] = useState(true);
  const [intentDetails, setIntentDetails] = useState();

  useEffect(() => {
    const getIntent = async () => {
      setLoader(true);
      try {
        if (xIntentId) {
          const { data } = await axios.get(`/payments/get-intent/${xIntentId}`);
          setStatus(data.status);
          setIntentDetails(data);
        } else {
          const { data } = await axios.get(
            `/subscription/get-subscription-public/${subscriptionId}`
          );
          setStatus(data.status);
        }
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoader(false);
      }
    };

    getIntent();
  }, []);

  return { status, loader, intentDetails };
};
