import React from "react";
import ApplePayLogo from "../../../assets/paymentMethods/Apple_Pay-Logo.png";
import CheckoutFooter from "./../../UI/CheckoutFooter";

const ApplePayCheckoutPage = () => {
  return (
    <div className="border border-[#e6e6e6] p-3 rounded-lg">
      <img className="mb-3 h-[2em] w-auto" src={ApplePayLogo} alt="Apple Pay" />
      <p className="text-[#30313D]">Apple Pay selected.</p>
      <div className="h-[1px] bg-[#e6e6e6] w-full my-3"></div>
      <CheckoutFooter
        text={
          "Another step will appear to securely submit your payment information."
        }
      />
    </div>
  );
};

export default ApplePayCheckoutPage;
