/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Spin, Drawer } from "antd";
import { toast } from "react-hot-toast";

import { useSubscriptionContext } from "../../contexts/SubscriptionContext.jsx";

import { ImSpinner2 } from "react-icons/im";

import useSubsPrefetch from "../../hooks/Subscription/useSubsPrefetch.js";
import useSubsTriggerIntent from "../../hooks/Subscription/useSubsTriggerIntent.js";
import useSubsCalculateAmount from "../../hooks/Subscription/useSubsCalculateAmount.js";
import { useSubsGetMerchantLogo } from "../../hooks/Subscription/useSubsGetMerchantLogo.js";

import SubsMethod from "./Steps/SubsMethod";
import SubsPayment from "./Steps/SubsPayment.jsx";
import SubsYourDetails from "./Steps/SubsYourDetails.jsx";

import Header from "../UI/Header.jsx";
import PaymentDetails from "../UI/PaymentDetails.jsx";
import WarningToast from "../UI/Toasts/WarningToast.jsx";
import CustomCurrencyField from "../UI/CustomCurrencyField.jsx";

import SubsUserBox from "./SubsUserBox.jsx";
import SubsTransDetails from "./SubsTransDetails.jsx";
import SubsProceedButton from "./SubsProceedButton.jsx";
import SubsPaymentStatusView from "./SubsPaymentStatusView.jsx";


const SubsContainer = () => {
  const {
    subscriptionId,
    loading,
    status,
    setStatus,
    intentDetails,
    setIntentDetails,
    loadingMain,
    setLoadingMain,
    mode,
    loadingMethods,
    paymentMethod,
    setPaymentMethod,
    currency,
    setCurrency,
    setPaymentError,
    setIsPaymentBtnDisabled,
    errorMessage,
    callbackUrl,
  } = useSubscriptionContext();
  const {
    merchantName,
    currenciesOptionsList,
    billingDetails,
    setBillingDetails,
    getSubsPaymentMethod,
    paymentMethodList,
  } = useSubsPrefetch();
  const { merchantLogo } = useSubsGetMerchantLogo({
    subscriptionId,
    setLoadingMain,
    setStatus,
  });
  const { triggerIntent } = useSubsTriggerIntent();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      (!billingDetails?.name ||
        !billingDetails?.email ||
        !billingDetails?.country) &&
      !loading
    ) {
      setOpen(true);
    }
  }, [loading]);

  const onClose = () => {
    if (
      billingDetails?.name &&
      billingDetails?.email &&
      billingDetails?.country
    ) {
      setOpen(false);
    } else {
      toast.custom(
        (t) => (
          <WarningToast
            t={t}
            message="Please fill all the details. We collect this information to help combat fraud, and to keep your payment secure."
          />
        ),
        {
          id: "userDetails",
        }
      );
    }
  };

  const { amountLoading, getSubsCalculatedAmount } = useSubsCalculateAmount();

  const renderContent = () => {
    if (status) {
      return (
        <SubsPaymentStatusView status={status} errorMessage={errorMessage} />
      );
    }

    return (
      <>
        <Spin
          spinning={loading}
          indicator={<ImSpinner2 className="animate-spin" />}
          size="large"
          className=""
        >
          <div className="flex flex-col w-full h-full gap-7">
            {/* User details Sections */}
            {billingDetails?.name &&
              billingDetails?.email &&
              billingDetails?.country && (
                <SubsUserBox
                  open={open}
                  setOpen={setOpen}
                  billingDetails={billingDetails}
                />
              )}

            {/* Payment Details Sections */}
            <div className="flex items-center justify-between w-full gap-3">
              <PaymentDetails
                intentDetails={intentDetails}
                setIntentDetails={setIntentDetails}
                paymentMethod={paymentMethod}
                paymentMethodList={paymentMethodList}
                setPaymentError={setPaymentError}
                setIsPaymentBtnDisabled={setIsPaymentBtnDisabled}
              />
              <CustomCurrencyField
                currency={currency}
                setCurrency={setCurrency}
                currenciesOptionsList={currenciesOptionsList}
                getCalculatedAmount={getSubsCalculatedAmount}
                getPaymentMethod={getSubsPaymentMethod}
                setIntentDetails={setIntentDetails}
                setPaymentError={setPaymentError}
                setIsPaymentBtnDisabled={setIsPaymentBtnDisabled}
              />
            </div>

            {/* Payment Body */}
            {!intentDetails ? (
              <SubsMethod
                paymentMethodList={paymentMethodList}
                setPaymentMethod={setPaymentMethod}
                loading={loadingMethods}
                triggerIntent={triggerIntent}
                currency={currency}
              />
            ) : (
              <SubsPayment />
            )}
          </div>

          {/* Footer Sections */}
          <div className="fixed sm:w-[416px] bottom-9 hidden sm:block bg-white">
            <SubsTransDetails
              merchantName={merchantName}
              currenciesOptionsList={currenciesOptionsList}
              amountLoading={amountLoading}
            />
            <SubsProceedButton />
          </div>
        </Spin>
      </>
    );
  };

  return (
    <>
      <div
        className="bg-white h-full w-full max-w-[32rem] sm:drop-shadow-2xl sm:rounded-2xl sm:py-9 sm:px-12 pt-8 pb-[157px] px-6 sm:backdrop-blur sm:shadow-2xl overflow-y-auto relative"
        id="container"
      >
        <Spin
          spinning={loadingMain}
          indicator={<ImSpinner2 className="animate-spin" />}
          size="large"
        >
          {/* Header */}
          <Header
            setLoadingMain={setLoadingMain}
            mode={mode}
            merchantLogo={merchantLogo}
            callbackUrl={callbackUrl}
            subscriptionId={subscriptionId}
          />
          {renderContent()}
        </Spin>

        {/* User Details Drawer */}
        <Drawer
          onClose={onClose}
          closable={false}
          open={open && !status}
          placement="bottom"
          getContainer={false}
          autoFocus={false}
          className="rounded-t-xl"
          rootClassName="hidden sm:block"
          height="full"
        >
          <SubsYourDetails
            setOpen={setOpen}
            billingDetails={billingDetails}
            setBillingDetails={setBillingDetails}
          />
        </Drawer>
      </div>
      {/* Mobile Views */}
      {!status && !loading && (
        <div className="fixed w-full sm:w-[416px] bottom-0 pb-6 px-6 sm:hidden bg-white">
          <SubsTransDetails
            merchantName={merchantName}
            currenciesOptionsList={currenciesOptionsList}
            amountLoading={amountLoading}
          />
          <SubsProceedButton />
        </div>
      )}
      <Drawer
        onClose={onClose}
        closable={false}
        open={open && !status}
        placement="bottom"
        autoFocus={false}
        className="rounded-t-xl"
        rootClassName="sm:hidden"
        height="full"
      >
        <SubsYourDetails
          setOpen={setOpen}
          billingDetails={billingDetails}
          setBillingDetails={setBillingDetails}
        />
      </Drawer>
    </>
  );
};

export default SubsContainer;
