/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";

import { Form } from "antd";
import InputField from "../../UI/FormFields/InputField";
import SelectField from "./../../UI/FormFields/SelectField";
import CheckoutFooter from "../../UI/CheckoutFooter";
import { fieldNames } from "../../../constants/fieldNames";

const KlarnaCheckout = () => {
  const { billingDetails } = useOneTimePaymentsContext();
  const countries = [
    {
      label: "🇺🇸 United States",
      value: "US",
    },
  ];
  const form = Form.useFormInstance();

  useEffect(() => {
    const initialValues = {
      firstName: billingDetails?.name?.split(" ")[0],
      lastName: billingDetails?.name?.split(" ")[1] || "",
      email: billingDetails?.email,
      zip: billingDetails?.pincode,
      country: "US",
    };

    form.setFieldsValue(initialValues);
  }, [billingDetails]);

  return (
    <div>
      {/* First Name - Last Name */}
      <div className="grid grid-cols-2 gap-4">
        <InputField
          name={fieldNames.firstName}
          label={"First Name"}
          required={true}
          placeholder={"First name"}
        />
        <InputField
          name={fieldNames.lastName}
          label={"Last Name"}
          required={true}
          placeholder={"Last name"}
        />
      </div>

      {/* Email */}
      <InputField
        name={fieldNames.email}
        label={"Email"}
        required={true}
        placeholder={"Email Address"}
      />
      {/* Phone */}
      <InputField
        name={fieldNames.phone}
        label={"Phone"}
        required={true}
        placeholder={"Phone"}
      />

      {/* Address Line */}
      <InputField
        name={fieldNames.address}
        label={"Address"}
        required={true}
        placeholder={"Address"}
      />

      {/* city - state */}
      <div className="grid grid-cols-2 gap-4">
        <InputField
          name={fieldNames.city}
          label={"City"}
          required={true}
          placeholder={"City"}
        />
        <InputField
          name={fieldNames.state}
          label={"State"}
          required={true}
          placeholder={"State"}
        />
      </div>

        {/* zip - Country */}
        <div className="grid grid-cols-2 gap-4">
          <InputField
            name={fieldNames.zip}
            label={"Zip"}
            required={true}
            placeholder={"Zip"}
          />
          <SelectField
            name={fieldNames.country}
            label={"Country"}
            options={countries}
            disabled={true}
            required={true}
          />
        </div>
      <CheckoutFooter
        text={
          "After submission, you will be redirected to securely complete next steps."
        }
      />
    </div>
  );
};

export default KlarnaCheckout;
