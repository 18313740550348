/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";

const SubscriptionContext = createContext({});

export const SubscriptionProvider = (props) => {
  const urlParam = new URLSearchParams(window.location.search);
  const [subscriptionId, setSubscriptionId] = useState(
    urlParam.get("subscription_id")
  );
  const [currency, setCurrency] = useState(urlParam.get("currency") || "USD");
  const [secret, setSecret] = useState(urlParam.get("secret"));
  const [amount, setAmount] = useState();
  const [upfrontAmount, setUpfrontAmount] = useState();
  const [merchantName, setMerchantName] = useState();
  const [paymentError, setPaymentError] = useState(false);
  const [status, setStatus] = useState(null);
  const [mode, setMode] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingMethods, setLoadingMethods] = useState(false);

  const [isPaymentBtnDisabled, setIsPaymentBtnDisabled] = useState(true);
  const [intentDetails, setIntentDetails] = useState();
  const [interval, setInterval] = useState();
  const [intervalCount, setIntervalCount] = useState(0);
  const [pg, setPg] = useState();
  const [callbackUrl, setCallbackUrl] = useState();
  const [pgPublicKey, setPgPublicKey] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [billingDetails, setBillingDetails] = useState();

  const [stripeElement, setStripeElement] = useState(); // Stripe
  const [stripePromise, setStripePromise] = useState(); // Stripe

  return (
    <SubscriptionContext.Provider
      value={{
        currency,
        setCurrency,
        amount,
        setAmount,
        paymentMethodList,
        setPaymentMethodList,
        merchantName,
        setMerchantName,
        intentDetails,
        setIntentDetails,
        loading,
        setLoading,
        loadingMain,
        setLoadingMain,
        stripeElement,
        setStripeElement,
        paymentError,
        setPaymentError,
        status,
        setStatus,
        mode,
        setMode,
        loadingMethods,
        setLoadingMethods,
        paymentMethod,
        setPaymentMethod,
        isPaymentBtnDisabled,
        setIsPaymentBtnDisabled,
        interval,
        setInterval,
        pg,
        setPg,
        intervalCount,
        setIntervalCount,
        callbackUrl,
        setCallbackUrl,
        pgPublicKey,
        setPgPublicKey,
        clientSecret,
        setClientSecret,
        errorMessage,
        setErrorMessage,
        subscriptionId,
        setSubscriptionId,
        stripePromise,
        setStripePromise,
        billingDetails,
        setBillingDetails,
        secret,
        setSecret,
        upfrontAmount,
        setUpfrontAmount,
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  return useContext(SubscriptionContext);
};
