import { useState } from "react";

import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { PG } from "../../constants/enums/pg";

import { useStripePayments } from "./useStripePayments";
import { useTokyoPayments } from "./useTokyoPayments";
// import { useAuthnet } from "./useAuthnet";

const useTriggerPayment = () => {
  const { intentDetails } = useOneTimePaymentsContext();
  const [payBtnLoading, setPayBtnLoading] = useState(false);
  const { tokyoChargeAsync } = useTokyoPayments({ setPayBtnLoading });
  const { stripePay } = useStripePayments({ setPayBtnLoading });
  // const { chargeCard } = useAuthnet({ setPayBtnLoading });

  const triggerPayment = async () => {
    switch (intentDetails.pg) {
      case PG.STRIPE:
        await stripePay();
        break;
      // case PG.AUTHNET:
      //   await chargeCard();
      //   break;
      case PG.TOKYO:
        await tokyoChargeAsync();
        break;
      default:
        break;
    }
  };

  return { triggerPayment, payBtnLoading };
};

export default useTriggerPayment;
