import { posthog } from "posthog-js";
import React from "react";
import SubsContainer from "../components/Subscription/SubsContainer";
import GridBackground from "../components/UI/GridBackground";
import { SubscriptionProvider } from "../contexts/SubscriptionContext";

const SubscriptionsV3 = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionId = urlParams.get("subscription_id");

  posthog.people.set({
    xPayId: subscriptionId,
  });

  return (
    <div>
      <GridBackground>
        <SubscriptionProvider>
          <SubsContainer />
        </SubscriptionProvider>
      </GridBackground>
    </div>
  );
};

export default SubscriptionsV3;
