import { sendEvent } from "../../utils/EventStreaming/eventService";
import { EVENT_NAMES } from "../../constants/enums/eventNames";

import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { PaymentMethod } from "../../constants/enums/paymentMethod";
import { STATUS } from "../../constants/enums/status";

export const useStripePayments = ({ setPayBtnLoading }) => {
  const {
    intentDetails,
    xIntentId,
    stripePromise,
    stripeElement,
    setPaymentError,
    setStatus,
    currency,
    amount,
    paymentMethod,
    paymentRequest,
    addressElement,
    billingDetails,
    secret,
  } = useOneTimePaymentsContext();

  const ERROR_MESSAGE =
    "Your payment was declined by the payment method provider.";

  const generateReturnUrl = () => {
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("pg", intentDetails.pg);
    returnUrl.searchParams.set("callbackUrl", intentDetails.callbackUrl);
    returnUrl.searchParams.set("paymentMethod", paymentMethod);
    returnUrl.searchParams.set("currency", currency);
    returnUrl.searchParams.set("pgPublicKey", intentDetails.pgPublicKey);
    returnUrl.searchParams.set("amount", amount);
    returnUrl.searchParams.set("secret", secret);

    return returnUrl.href;
  };

  const scrollToContainerTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const element = document.getElementById("payment");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
        marginTop: "-100px",
      });
    }
  };

  const walletPayments = async () => {
    if (!paymentRequest) {
      return;
    }

    paymentRequest.once("paymentmethod", async (event) => {
      const { error, paymentIntent } = await stripePromise.confirmCardPayment(
        intentDetails?.clientSecret,
        { payment_method: event.paymentMethod.id }
      );

      if (error) {
        event.complete("fail");
        scrollToContainerTop();
        if (error.type === "card_error" || error.type === "validation_error") {
          setPaymentError(error.message);
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
            errorMessage: error.message,
          });
        } else {
          setPaymentError(ERROR_MESSAGE);
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
            errorMessage: ERROR_MESSAGE,
          });
        }
      } else if (paymentIntent) {
        event.complete("success");
        if (paymentIntent.status === "succeeded") {
          setStatus(STATUS.SUCCESS);
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_SUCCESS, {});
        } else {
          scrollToContainerTop();
          setPaymentError(ERROR_MESSAGE);
          sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
            errorMessage: ERROR_MESSAGE,
          });
        }
      }
    });

    paymentRequest.on("cancel", function () {
      setPaymentError(ERROR_MESSAGE);
      sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
        errorMessage: ERROR_MESSAGE,
      });
    });

    paymentRequest.show();
  };

  const normalStripeFlow = async () => {
    const elements = stripeElement;
    if (!stripePromise || !stripeElement) {
      return;
    }
    const addressDetails = await addressElement?.getValue();
    setPayBtnLoading(true);
    const returnUrl = generateReturnUrl();

    const { error, paymentIntent } = await stripePromise.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
        ...(paymentMethod === PaymentMethod.AFTERPAY && {
          payment_method_data: {
            billing_details: {
              name: addressDetails?.value?.name || billingDetails?.name,
            },
          },
        }),
      },
      redirect: "if_required",
    });

    if (error) {
      scrollToContainerTop();
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentError(error.message);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: error.message,
        });
      } else {
        setPaymentError(ERROR_MESSAGE);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: ERROR_MESSAGE,
        });
      }
    } else if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        setStatus(STATUS.SUCCESS);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_SUCCESS, {});
      } else {
        scrollToContainerTop();
        setPaymentError(ERROR_MESSAGE);
        sendEvent(xIntentId, EVENT_NAMES.INTENT_STATUS_FAILED, {
          errorMessage: ERROR_MESSAGE,
        });
      }
    }
  };

  const stripePay = async () => {
    if (
      paymentMethod === PaymentMethod.GOOGLE_PAY ||
      paymentMethod === PaymentMethod.APPLE_PAY
    ) {
      await walletPayments();
    } else {
      await normalStripeFlow();
    }

    setPayBtnLoading(false);
  };

  return { stripePay };
};
