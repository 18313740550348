import axios from "../../axios";

import { PaymentMethod } from "../../constants/enums/paymentMethod";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { ErrorHandler } from "./../../utils/ErrorHandler";
import { STATUS } from "./../../constants/enums/status";

export const useTokyoPayments = ({ setPayBtnLoading }) => {
  const {
    intentDetails,
    xIntentId,
    setPaymentError,
    setStatus,
    currency,
    paymentMethod,
    form,
    setIframeUrl,
    setIsIframeModalOpen,
    amount,
    secret,
  } = useOneTimePaymentsContext();

  const generateReturnUrl = () => {
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("pg", intentDetails.pg);
    returnUrl.searchParams.set("callbackUrl", intentDetails.callbackUrl);
    returnUrl.searchParams.set("paymentMethod", paymentMethod);
    returnUrl.searchParams.set("currency", currency);
    returnUrl.searchParams.set("pgPublicKey", intentDetails.pgPublicKey);
    returnUrl.searchParams.set("amount", amount);
    returnUrl.searchParams.set("secret", secret);

    return returnUrl.href;
  };

  const handleStatus = (data, isIframe) => {
    if (data?.status === STATUS.SUCCESS) {
      setStatus(STATUS.SUCCESS);
    } else if (data?.redirectUrl) {
      if (!isIframe) {
        window.location.replace(data.redirectUrl);
      } else {
        setIframeUrl(data.redirectUrl);
        setIsIframeModalOpen(true);
        setPayBtnLoading(false);
      }
    } else {
      setPaymentError(data?.errorMessage);
      setPayBtnLoading(false);
    }
  };

  const tokyoCardPayload = (values) => {
    const cardValidity = values.cardValidity.split(" / ");
    const cardNumber = values.cardNumber.replace(/\s/g, "");
    const cardDetails = {
      cardNumber: cardNumber,
      cardHolderName: values.cardName,
      expiryMonth: cardValidity[0],
      expiryYear: cardValidity[1],
      cardCode: values.cardSecurityCode,
    };

    const iframeReturnUrl =
      window.origin + "/iframe/handler/?xpay_intent_id=" + xIntentId;

    return {
      intentId: xIntentId,
      cardDetails: cardDetails,
      confirmationUrl: iframeReturnUrl,
      pg: intentDetails.pg,
      paymentMethod: paymentMethod,
      currency: currency,
    };
  };

  const tokyoPaypalPayload = (values) => {
    return {
      intentId: xIntentId,
      confirmationUrl: generateReturnUrl(),
      pg: intentDetails.pg,
      paymentMethod: paymentMethod,
      currency: currency,
    };
  };

  const tokyoVenmoPayload = (values) => {
    return {
      intentId: xIntentId,
      confirmationUrl: generateReturnUrl(),
      pg: intentDetails.pg,
      paymentMethod: paymentMethod,
      currency: currency,
    };
  };

  const tokyoAfterpayPayload = (values) => {
    return {
      intentId: xIntentId,
      email: values.email,
      confirmationUrl: generateReturnUrl(),
      pg: intentDetails.pg,
      paymentMethod: paymentMethod,
      currency: currency,
    };
  };

  const tokyoKlarnaPayload = (values) => {
    return {
      intentId: xIntentId,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      country: values.country,
      confirmationUrl: generateReturnUrl(),
      pg: intentDetails.pg,
      paymentMethod: paymentMethod,
      currency: currency,
    };
  };

  const getRequestPayload = ({ paymentMethod, values }) => {
    switch (paymentMethod) {
      case PaymentMethod.CARD:
        return tokyoCardPayload(values);

      case PaymentMethod.PAYPAL:
        return tokyoPaypalPayload(values);

      case PaymentMethod.VENMO:
        return tokyoVenmoPayload(values);

      case PaymentMethod.AFTERPAY:
        return tokyoAfterpayPayload(values);

      case PaymentMethod.KLARNA:
        return tokyoKlarnaPayload(values);

      default:
        return {};
    }
  };

  const isIframe = ({ paymentMethod }) => {
    return paymentMethod === PaymentMethod.CARD;
  };

  const tokyoChargeAsync = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        setPayBtnLoading(true);
        const payload = getRequestPayload({
          paymentMethod,
          values,
        });
        try {
          const { data } = await axios.post(`/payments/charge-async`, payload);
          handleStatus(data, isIframe({ paymentMethod }));
        } catch (error) {
          setPayBtnLoading(false);
          ErrorHandler(error, setStatus);
        }
      })
      .catch((error) => {
        setPayBtnLoading(false);
        setPaymentError("Please fill in all required fields.");

        const fieldElement = document.querySelector(
          `[name="${error.errorFields[0].name}"]`
        );
        if (fieldElement) {
          fieldElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      });
  };

  return { tokyoChargeAsync };
};
